import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "ait-lookup"
    }}>{`AIT Lookup`}</h2>
    <p><strong parentName="p">{`A`}</strong>{`rtificial `}<strong parentName="p">{`I`}</strong>{`nflated `}<strong parentName="p">{`T`}</strong>{`raffic Lookup:`}</p>
    <p>{`Utilizing numerous data sources and combing proprietary BI and AI modules and processes, we have build a business intelligence information system over the last years, which enables us to provide you with number intelligence data!`}</p>
    <p>{`Our AIT specific solution is giving you indicative data:`}</p>
    <ul>
      <li parentName="ul">{`If the destination number is a "real phone"`}</li>
      <li parentName="ul">{`If the number potentially part of artificial traffic`}</li>
      <li parentName="ul">{`If the number potentially a virtual SIM and/or Long Number`}</li>
    </ul>
    <h3 {...{
      "id": "http-endpoint"
    }}>{`HTTP Endpoint`}</h3>
    <p>{`The lookup functionality can be reached at `}<a parentName="p" {...{
        "href": "https://guard.anymessage.cloud/ait"
      }}>{`https://guard.anymessage.cloud/ait`}</a>{` via GET.`}</p>
    <p>{`Please note, that this endpoint offers always the latest version of the lookup and future versions may break their compatibility to previous versions.
To mitigate this issue, we also offer a versioned API that can be found at `}<a parentName="p" {...{
        "href": "https://guard.anymessage.cloud/v1/ait"
      }}>{`https://guard.anymessage.cloud/v1/ait`}</a>{` via GET.`}</p>
    <h3 {...{
      "id": "authentication"
    }}>{`Authentication`}</h3>
    <p>{`We use JSON Web Tokens (JWT) for authentication. Please submit the JWT in one of the following two methods:`}</p>
    <ol>
      <li parentName="ol">{`via HTTP header like`}<ul parentName="li">
          <li parentName="ul">{`Authorization: Bearer jwt`}</li>
        </ul></li>
      <li parentName="ol">{`via HTTP GET parameter like`}<ul parentName="li">
          <li parentName="ul">{`token=jwt`}</li>
        </ul></li>
    </ol>
    <h3 {...{
      "id": "get-parameters"
    }}>{`GET Parameters`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`token`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`provided from AnyMessage to authenticate yourself`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9...`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`msisdn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the MSISDN to check; required to be numeric`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`491520123456789`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`*`}{` see `}<a parentName="p" {...{
        "href": "#authentication"
      }}>{`Authentication`}</a></p>
    <h3 {...{
      "id": "responses"
    }}>{`Responses`}</h3>
    <h4 {...{
      "id": "on-success"
    }}>{`On success`}</h4>
    <p>{`A successful lookup will result in a 200 OK HTTP response containing a JSON HTTP entity with the following data:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`JSON type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`always present`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`msisdn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the MSISDN originally provided in the request`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`status`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the status code for the probability, how "real" the MSISDN is`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`probability`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`float`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the probability of how "real" the MSISDN is range from 0 to 100`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`*`}{` only available when using the premium service`}</p>
    <p>{`The status codes resolve in the following way:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`verbose status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`real`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the given MSISDN is almost certainly real`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`probably real`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the given MSISDN is rather real than fake`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`potentially fake`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the given MSISDN is potentially fake`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Examples:`}</p>
    <p>{`A response from the standard service looks like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "msisdn": "491520123456789",
    "status": 3
}
`}</code></pre>
    <p>{`A response from the premium service looks like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "msisdn": "491520123456789",
    "status": 3,
    "probability": 4.2
}
`}</code></pre>
    <h4 {...{
      "id": "on-failure"
    }}>{`On failure`}</h4>
    <p>{`A failed lookup will result in a 4xx or 5xx HTTP response containing a JSON HTTP entity with the following data:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`JSON type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`always present`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`msisdn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the MSISDN originally provided in the request`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the error description`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The following responses are possible in case of an error:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP status code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`JSON response entity*`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{"msisdn": ..., "error":"Invalid credentials"}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the "username" and "password" combination provided is incorrect`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{"msisdn": ..., "error":"User is blocked"}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the user is blocked`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{"msisdn": ..., "error":"Service is not enabled for this user"}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the user may not use this service`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`500`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`{"msisdn": ..., "error":"Internal error"}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`an internal error occurred`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`*`}{` parameter "msisdn" omitted for brevity`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "msisdn": "491520123456789",
    "error": "Invalid credentials"
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      